<div class="row mt-menu">
  <div class="col-md-6 offset-md-3">
    <div class="input-group">
      <input
        type="search"
        class="form-control rounded bg-dark text-white"
        placeholder="Search"
        aria-label="Search"
        aria-describedby="search-addon"
        [(ngModel)]="searchValue"
        (keyup.enter)="search()"
      />
      <button type="button" class="btn btn-primary btn-custom" (click)="search()">
        search
      </button>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-md-12">

    <div class="ph-item" *ngIf="showSpinner" >
      <div class="ph-col-12">
        <div class="ph-row mb-2 mt-2">
          <div class="ph-col-2 big"></div>
          <div class="ph-col-10 empty big"></div>
        </div>
        
        <div class="row">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>

        
        <div class="row">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>

        
        <div class="row mb-2">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>

        
        <div class="row">
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
          </div>
          <div class="col-md-2">
            <div class="ph-picture"></div>
        </div>
      </div>
  </div>
  </div>




      <p class="text-center mt-4" *ngIf="showNotFound">No content found.</p>

  </div>
</div>


<div class="row" *ngIf="showlist">

    <div class="col-md-12 mt-4  ml-5">
      <h3 class="search_content">Content: </h3>
    </div>
</div>
<div class="row ml-3 mr-3" *ngIf="showlist">
    
    <div class="col-md-2 mt-3 mb-3 col-6 col-sm-6" *ngFor="let c of contents">
      <div class="slick-item" >
        <div class="single-image"  [routerLink]="['/video/', c.uuid ]" >
            <div class="single-image-inside">
                <img src="{{c.thumbnail}}" alt="{{c.title}}">
                <p class="title-vdo">{{c.title}}</p>
            </div>
        </div>
    </div>
    </div>
</div>