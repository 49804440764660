<div class="featured-section">
    <ngx-slick-carousel [config]="sliderConfigLarge" #slickModalLarge="slick-carousel"  class="carousel sliderConfigLarge">
                        
        <div class="slider-single" *ngFor="let m of featuredSlider" ngxSlickItem>
            <div [ngClass]="{ 'slider-large': isDesktop, 'slider-large-tab': !isDesktop }">
                <div class="slider-large-image">
                    <img *ngIf="isDesktop" src="{{m.poster}}" alt="{{m.title}}">
                    <img *ngIf="!isDesktop" src="{{m.vertical_poster}}" alt="{{m.title}}">
                </div>
                <div class="titleBtn">
                    <h3 class="title">{{m.content.title}}</h3>
                    <p class="description">{{ m.content.synopsis }}</p>

                    <button class="play-button" [routerLink]="['/video/', m.content.uuid ]">
                        <span>Detail</span>
                    </button>
                </div>
                <div class="overlay-bg-image"></div>
            </div>
        </div>
    </ngx-slick-carousel>
    

    <div class="featured-bottom-section">
        <ngx-slick-carousel [config]="sliderConfigSmall" #slickModalSmall="slick-carousel" class="carousel sliderConfigSmall">
                            
            <div class="slider-single" *ngFor="let m of featuredSlider;let i = index" ngxSlickItem>
                <div class="slider-small">
                <img src="{{m.poster}}" (click)="slickModalSmall.slickGoTo(i);slickModalLarge.slickGoTo(i);activeSlide = i" [class]="i == activeSlide ? 'thumbnailActive' : ''"  alt="{{m.title}}">
                 </div>
            </div>
        </ngx-slick-carousel>
    </div>
</div>