<swiper [config]="swiperConfig">

    <ng-template swiperSlide *ngFor="let show of shows">
      <div class="hero-section" [ngStyle]="{'background-image': 'url(' + show.image + ')'}">
        <div class="overlay">
          <div class="content-wrapper">
            <div class="show-info">
              <span class="status">{{ show.status }}</span>
              <span class="season">{{ show.season }}</span>
              <span class="genre">{{ show.genre }}</span>
            </div>
            <h1 class="title">{{ show.title }}</h1>
            <p class="description">{{ show.description }}</p>
            <div class="button-group">
              <button class="details-btn">Details</button>
              <button class="add-btn">+</button>
              <button class="share-btn">Share</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

  </swiper>