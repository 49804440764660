import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlaylistsEntity } from 'src/app/models/featured-slider.model';
import { MovieService } from 'src/app/services/movie.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  subs: Subscription[] = [];
  singleSlide:any = {};
  playlists: PlaylistsEntity[] = [];
  featuredSlider: any = [];
  showFeaturedSlider: boolean = false;

  sliderConfig = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 2,
    initialSlide: 0,
    useTransform: false,
    variableWidth: false,
    arrow: true,
    autoplay: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
      dots: false,
      arrows: true,
        }
      },
      {
        breakpoint: 769,
        settings: {
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: false,
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      }
    ]
  }

  sliderConfigVert = {
    infinite: true,
    slidesToShow: 9,
    slidesToScroll: 2,
    initialSlide: 0,
    useTransform: false,
    variableWidth: false,
    arrow: true,
    autoplay: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
      dots: false,
      arrows: true,
        }
      },
      {
        breakpoint: 769,
        settings: {
          infinite: false,
          slidesToShow: 5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: false,
          slidesToShow: 2.9,
          slidesToScroll: 1
        }
      }
    ]
  }




  headerBGUrl: string;

  constructor(private movie: MovieService) { }

  ngOnInit(): void {
    this.subs.push(this.movie.getFeaturedSlider().subscribe( data => {
      if(data.code == 200){
        var mat = Math.floor(
          Math.random() * data.slider.length
        );

        this.singleSlide = data.slider[mat];
        this.featuredSlider = data.slider;
        this.playlists = data.data;
        this.showFeaturedSlider = true;
      }
    }))
  }

  ngOnDestroy(): void {
    this.subs.map(s => s.unsubscribe());
  }
}
