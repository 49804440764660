<div class="movie">
    <div class="movie__images">
        <div class="thumbnails">
            <ngx-slick-carousel [config]="sliderConfig" class="carousel">
                    <div class="slick-item" *ngFor="let m of playlist" ngxSlickItem>
                        <div class="single-image"  [routerLink]="['/video/', m.uuid ]" >
                            <div class="single-image-inside">
                                <img src="{{m.thumbnail}}" alt="{{m.title}}">

                                <div *ngIf="m.release_date">
                                    <p class="single-release">COMING {{moment(m.release_date).format('MMMM DD YYYY')}}</p>
                                </div>

                            </div>
                        </div>
                    </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>
