<div *ngFor="let list of playlists">
    <h2>{{list.playlist_id > 0 ? list.playlist.name : list.category.name}}</h2>
    <div *ngIf="list.playlist_id > 0">
    <div class="movie">
        <div class="movie__images">
            <div class="thumbnails">
                <ngx-slick-carousel [config]="sliderConfigVert" class="carousel" *ngIf="list.vertical">
                    
                        <div class="slick-item" *ngFor="let m of list.playlist.contents" ngxSlickItem>
                            <div class="single-image-vertical" *ngIf="list.vertical" [routerLink]="['/video/', m.uuid ]" >
                                <div class="single-image-inside">
                                    <img src="{{m.poster_vertical}}" alt="{{m.title}}">
                                </div>
                            </div>
                            <div class="single-image" *ngIf="!list.vertical" [routerLink]="['/video/', m.uuid ]" >
                                <div class="single-image-inside">
                                    <img src="{{m.thumbnail}}" alt="{{m.title}}">
                                </div>
                            </div>
                        </div>
                
                </ngx-slick-carousel>
                <ngx-slick-carousel [config]="sliderConfig" class="carousel" *ngIf="!list.vertical" >
                    
                        <div class="slick-item" *ngFor="let m of list.playlist.contents" ngxSlickItem>
                            <div class="single-image-vertical" *ngIf="list.vertical" [routerLink]="['/video/', m.uuid ]" >
                                <div class="single-image-inside">
                                    <img src="{{m.poster_vertical}}" alt="{{m.title}}">
                                </div>
                            </div>
                            <div class="single-image" *ngIf="!list.vertical" [routerLink]="['/video/', m.uuid ]" >
                                <div class="single-image-inside">
                                    <img src="{{m.thumbnail}}" alt="{{m.title}}">
                                </div>
                            </div>
                        </div>
                
                </ngx-slick-carousel>
            </div>
        </div>
    </div>

</div>
<div *ngIf="list.category_id > 0">
    <div class="movie">
        <div class="movie__images">
            <div class="thumbnails">
                <ngx-slick-carousel [config]="sliderConfig" class="carousel" *ngIf="!list.vertical" >
                    
                        <div class="slick-item" *ngFor="let m of list.category.contents" ngxSlickItem>
                            <div class="single-image-vertical" *ngIf="list.vertical" [routerLink]="['/video/', m.uuid ]" >
                                <div class="single-image-inside">
                                    <img src="{{m.poster_vertical}}" alt="{{m.title}}">
                                </div>
                            </div>
                            <div class="single-image" *ngIf="!list.vertical" [routerLink]="['/video/', m.uuid ]" >
                                <div class="single-image-inside">
                                    <img src="{{m.thumbnail}}" alt="{{m.title}}">
                                </div>
                            </div>
                        </div>
                
                </ngx-slick-carousel>
                <ngx-slick-carousel [config]="sliderConfigVert" class="carousel" *ngIf="list.vertical" >
                    
                        <div class="slick-item" *ngFor="let m of list.category.contents" ngxSlickItem>
                            <div class="single-image-vertical" *ngIf="list.vertical" [routerLink]="['/video/', m.uuid ]" >
                                <div class="single-image-inside">
                                    <img src="{{m.poster_vertical}}" alt="{{m.title}}">
                                </div>
                            </div>
                            <div class="single-image" *ngIf="!list.vertical" [routerLink]="['/video/', m.uuid ]" >
                                <div class="single-image-inside">
                                    <img src="{{m.thumbnail}}" alt="{{m.title}}">
                                </div>
                            </div>
                        </div>
                
                </ngx-slick-carousel>
            </div>
        </div>
    </div>

</div>
</div>
