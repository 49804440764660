import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

// interface ShowData {
//     image: string;
//     title: string;
//     description: string;
//     season: string;
//     genre: string;
//     status: string;
//   }

@Component({
  selector: 'hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss'],
  
})
export class HeroSectionComponent implements OnInit {
    @ViewChild(SwiperComponent) swiper!: SwiperComponent;
  constructor(

  ) { }

  
  shows: any[] = [
    {
      image: 'https://abraadmin.margalla.net/storage/posters/banner-106-1-1733175313.jpg',
      title: 'The Chronicles of Time',
      description: 'A journey through the ages, exploring the mysteries of time and space.',
      season: '1 Season',
      genre: 'Sci-Fi',
      status: 'New'
    },
    {
      image: 'assets/images/show2.jpg',
      title: 'Legends of the Lost',
      description: 'Uncover the secrets of ancient civilizations and their untold stories.',
      season: '2 Seasons',
      genre: 'Historical',
      status: 'Popular'
    },
    {
      image: 'assets/images/show3.jpg',
      title: 'Mystic Realms',
      description: 'Dive into a world of magic, mystery, and mythical creatures.',
      season: '3 Seasons',
      genre: 'Fantasy',
      status: 'Trending'
    },
    {
      image: 'assets/images/show4.jpg',
      title: 'Urban Tales',
      description: 'Stories from the heart of the city, where dreams and reality collide.',
      season: '1 Season',
      genre: 'Drama',
      status: 'New'
    },
    {
      image: 'assets/images/show5.jpg',
      title: 'Cosmic Adventures',
      description: 'Embark on an intergalactic journey to the farthest reaches of the universe.',
      season: '4 Seasons',
      genre: 'Adventure',
      status: 'Classic'
    }
  ];

  swiperConfig = {
    slidesPerView: 1,
    navigation: false,
    pagination: { clickable: true },
    loop: true,
    // autoplay: {
    //     delay: 5000, // Slide changes every 3 seconds
    //     disableOnInteraction: false, // Keep autoplay running even after user interactions
    //   },
    breakpoints: {
      320: {
        navigation: false
      },
      768: {
        navigation: true
      }
    }
  };

  @HostListener('window:resize', ['$event'])
  onResize() {
    // Swiper will automatically handle responsive behavior
    // Add any additional responsive logic here if needed
  }


  ngOnInit(): void {

  }


  onPrevClick() {
    console.log('Previous button clicked');
  }
  
  onNextClick() {
    console.log('Next button clicked');
  }


}
